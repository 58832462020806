import React from 'react';
import gallery1 from '../../images/gallery-1.jpg';
import gallery2 from '../../images/gallery-2.png';
import gallery3 from '../../images/gallery-3.jpg';



const Gallery = () => {
    return (
        <div className="gallery-area">
            <div className="container">
                <div className="gallery-upper">
                    <h2>We would love to have you</h2>
                </div>
                <div className="gallery-slider">
                    <div className="gallery-item">
                        <img src={gallery1} alt=""/>
                    </div>
                    <div className="gallery-item gallery2">
                        <img src={gallery2} alt=""/>
                    </div>
                    <div className="gallery-item">
                        <img src={gallery3} alt=""/>
                    </div>
                    {/* <div className="gallery-arrow">
                        <img className="gallery-left" src="images/gallery-left.png" alt=""/>
                        <img className="gallery-right" src="images/gallery-right.png" alt=""/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Gallery;