import React, { useEffect } from 'react';


const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
        <div className="education-container">
            <h1 className="education-title">Our Cancellation Policy</h1>
        </div>
        
        <div className="privacy-policy-container">
            <p>
             All appointments must be canceled or rescheduled at least 48 hours prior to your appointment. 
             Any cancellation or reschedules after that will result in a cancellation fee which will be 50% of your service.
            </p>
            <br/>
            <br/>
            <p>
            Before beginning any service it is highly recommended that EVERY client use the changing room to
            remove and store their shirt and put on the provided smock. This way Habit can guarantee that there
            will be no damage to your clothing. Habit Salon and our stylist will not be held responsible for any
            clothing damage if the client chooses to forgo this request. Also, please remove ALL jewelry. Habit
            Salon and the stylist will not be held liable for any lost jewelry or injuries sustained from wearing
            jewelry.
            <br/>
            <br/>
            Habit Salon and each stylist strive for nothing less than perfection. We highly recommend booking
            your appointment on a day when you have no time restrictions to avoid stress for you and our Habit
            team. Your Habit stylist may or may not use an assistant during your appointment. Keep in mind that
            even though your stylist may not be doing every step of the service themselves, they oversee
            everything. Our highly trained assistants have been put through a rigorous training program, but your
            Habit stylist will be the one directing the entire process, including formulating all hair color used
            according to each client’s goal. At Habit we are proud to educate fellow stylists from around the
            world. At times there may be shadowing stylists that may observe your appointment.
            Consultation: At Habit we have all new clients fill out this questionnaire prior to, or at consultation.
            This helps the Stylist to prepare for your appointment. Sometimes we may ask that you send current
            pictures of your hair and goal photos. If you have not sent in photos then your stylist will review
            these items with you in her chair. If you need inspiration you can always reference our Instagram
            @habitsalon. After a consultation both the client and the stylist/salon have full discretion on whether
            to move forward. Either party may cancel the upcoming or current appointment and/or refuse
            service, for any reason. Client gives permission for their photo and image to be used on social media
            sites such as, but not limited to Instagram, Pinterest and Tik-Tok.***Please note: prices may vary
            between Habit stylists and prices may increase depending on stylists experience level. It is the
            client’s responsibility to confirm pricing prior to or at appointment time.
            <br/>
            <br/>
            Color: Please remember that achieving great hair also means maintaining healthy hair. Your dream
            hair will likely take several appointments. Please be patient and trust the process. Virgin hair,
            previously colored-treated hair and ESPECIALLY “box-dyed hair” all react differently and
            unpredictably. Depending on the ultimate goal of the client, the stylist will do everything possible to
            achieve the desired outcome while doing all they can to maintain the condition of the hair.
            Immediate, one day transformations are not always realistic and can be damaging & difficult.
            Depending on the health and color of the hair sometimes goals may not be achievable. Appointment
            time & amount budgeted for the service are also factors to keep in mind. We thank you for having
            patience during this process. A Habit Salon stylist will always err on the side of keeping your hair
            safe and healthy. Before proceeding with any color service make certain that you understand the
            estimated price and number of sessions your stylist will require.
            <br/>
            <br/>
            Extensions: If you are getting any type of extension please be aware that due to the tension caused
            from the beads and/or even tension on the hair there is a minimal chance that slight hair loss may
            occur. Please remember that adding anything to your hair will take time for your body to adjust and
            please remember that you are gaining new hair through extensions in exchange for (potentially) very
            minimal temporary hair loss around the pressure points.
            <br/>
            <br/>
            Habit Salon only guarantees extension services using hair from our own stock. We want your
            experience to be the BEST it can be, and we can only create those results when using our own high
            quality extensions and tools. If you decide to proceed with a service using hair sourced from
            somewhere else, even if agreed upon by your stylist, Habit and your stylist will not be responsible or
            liable to remove, move-up, color, fix or replace any hair not purchased at Habit Salon. Please know
            that prior to your appointment it is your responsibility to have all extension hair that was not installed
            at Habit removed, especially tape-in extension hair and glue. We do not have the tools and products
            needed for their removal. Otherwise Habit may charge for removal and the client releases Habit and
            their stylist of all liability regarding the removal process. Habit Salon and your stylist are not
            responsible for the removal of any hair and Allergies: At Habit Salon we use the best quality products
            available in order to keep the clients hair healthy and achieve their goals. Habit is not responsible for
            any allergic reactions or sensitivities that may occur during or after the appointment- whether or not
            these allergies are previously known to the client.
            <br/>
            <br/>
            Refund Policy: Habit Salon does NOT issue refunds. At the same time Habit Salon and each stylist
            strive for 100% satisfaction. We know that doing great hair and having a great experience is the key
            to happiness and success. If you are unhappy with your service we do offer an adjustment the same
            week (within 7 days) as your service if there is an issue, within reason. The stylist that performed the
            service may adjust it to better meet the original goal at no charge. Habit Salon has the right to
            consult first and discuss with clients about the appointment. If the client wants additional services
            that weren’t discussed in the consultation then this would be a new service and may come with
            additional fees.
            <br/>
        
            <br/>
            The undersigned client has read and understands these items and agrees to them:
            
            <br/>
            <br/>
            
            Extensions: Clients getting any type of extension please be aware that due to the tension caused from
            the beads and/or even tension on the hair there is a minimal chance that slight hair loss may occur.
            Please remember that adding anything to your hair will take time for your body to adjust to, also please
            remember that you are gaining new hair through extensions in exchange for (potentially) very minimal
            temporary hair loss around the pressure points.
            <br/>
            <br/>
            Habit Salon does not require the use of any specific type of hair extension company. Habit uses many
            different companies for their hair extensions. If any questions please ask stylists for more details. Habit
            Salon only installs and stands behind hair from our own inventory.
            <br/>
            <br/>
            We want your experience to be the BEST it can be and our experience shows that Habit making sure
            your hair is THE BEST quality in the world is key. Habit Salon and your stylist are not responsible for the
            removal of any hair and especially the removal of tape-in extension hair and glue. It is the client’s
            responsibility to have hair that was not installed at habit removed prior to appointment otherwise Habit
            may charge for removal and the clients releases Habit of all liability regarding the removal process. In
            addition, Habit is not responsible to remove, move-up or tone hair not purchased at Habit Salon.
            Stylists may choose NOT to color, tone, cut or move-up hair not purchased from Habit Salon. If the stylist
            is willing to work with the client, the client understands that the results are NOT guaranteed. The stylist is
            limited based on the quality and condition of the hair purchased from outside sources. There are also
            unknown variables the stylist cannot predict or be liable for when working with hair that is not our own.
            There is a risk when coloring extensions and our stylists and Habit Salon will not be held responsible.
            I acknowledge that hair extensions are very sensitive and different from human hair. I have been informed
            of the daily maintenance procedure. I will follow the daily maintenance procedure to keep my extensions
            in the best condition possible. Using the best quality products and styling tools will help maintain the
            health and life of the extensions.
            <br/>
            <br/>
            I acknowledge that swimming in pools, rivers and the ocean can lead to dry and damaged hair. Avoid
            getting sunscreen on the hair. Avoid windy environments. Never sleep on wet hair. Never, under any
            circumstance use purple shampoo on your extensions. For all other care instructions please talk to your
            stylist and request an 'Extension Care Card'. For more care instructions, please visit https://
            habitsalon.com/learn
            <br/>
            <br/>
            I acknowledge that I have inspected and approved the hair extensions that are to be installed.
            In the event that I decide not to keep the hair extensions, I am fully responsible for the total payment of
            services rendered. Used hair cannot be returned. I also understanding the explanations of the entire
            procedure. I understand that if an allergic reaction occurs, I will not hold my technician or salon at fault.
            The charge for the removal of hair extensions is not included in the original fee. I have read this release
            form in it’s entirety, and I voluntarily accept the terms of the release by affixing my signature below, and
            warrant that I fully understand it’s understanding.I have read and agree to these terms. 
            </p>

        </div>
        </>
    )
};

export default Terms;