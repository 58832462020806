import React from 'react';
import Habit1 from '../images/habit-1.jpg';
import Habit2 from '../images/habit-3.jpg';
import Habit3 from '../images/habit-2.jpg';

const SalonLocations = () => {
  return (
    <div className="salon-container">
        <div className="single-salon-container">
            <img src={Habit1} alt="" className="salon-image" />
            <div className="salon-meta-container">
                <h2 className="salon-title">Habit 1 (Gilbert, AZ)</h2>
                <p className="salon-address">4331 E Baseline Rd <br/> Suite 108 <br/>  Gilbert, AZ 85234</p>
                <a className="salon-phone" href="tel:4804625692">(480) 462 - 5692</a>
                {/* <a id="start-consult" className="start-consultation mr-10">Start a consultation</a> */}
                <a href="https://dashboard.boulevard.io/booking/businesses/2fb0d8b1-5dc2-4864-aefb-5ef8b8d22e90/widget#/visit-type" target="_blank" rel="noopener noreferrer" className="start-consultation">Book Appointment</a>
                
            </div>
        </div>

        <div className="single-salon-container">
            <img src={Habit2} alt="" className="salon-image" />
            <div className="salon-meta-container">
                <h2 className="salon-title">Habit 2 (Gilbert, AZ)</h2>
                <p className="salon-address">4341 E Baseline Rd <br/> Suite 104 <br/>  Gilbert, AZ 85234</p>
                <a className="salon-phone" href="tel:4804625692">(480) 462 - 5692</a>
                {/* <a id="start-consult" className="start-consultation mr-10">Start a consultation</a> */}
                <a href="https://dashboard.boulevard.io/booking/businesses/2fb0d8b1-5dc2-4864-aefb-5ef8b8d22e90/widget#/visit-type" target="_blank" rel="noopener noreferrer" className="start-consultation">Book Appointment</a>
            </div>
        </div>

        <div className="single-salon-container">
            <img src={Habit3} alt="" className="salon-image" />
            <div className="salon-meta-container">
                <h2 className="salon-title">Habit 3 (Costa Mesa, CA)</h2>
                <p className="salon-address">3033 Bristol St. <br/> Costa Mesa, CA 92626</p>
                <a className="salon-phone" href="tel:4804625692">(480) 462 - 5692</a>
                {/* <a id="start-consult" className="start-consultation mr-10">Start a consultation</a> */}
                <a href="https://dashboard.boulevard.io/booking/businesses/2fb0d8b1-5dc2-4864-aefb-5ef8b8d22e90/widget#/visit-type" target="_blank" rel="noopener noreferrer" className="start-consultation">Book Appointment</a>
            </div>
        </div>
    </div>
  );
}

export default SalonLocations;
