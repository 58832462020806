import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import stylistData from '../../data/stylists.json';
import stylistBadge from '../../images/Asset-2.png';

const StylistList = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Sort the stylistData by first_name
    const sortedStylistData = stylistData.sort((a, b) => {
        const nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });
    
    return (
        <Fragment>
            <header className="stylist-header-area">
                <div className="container">
                <div className="header-cnt">
                        <p>Meet our stylists</p>
                    </div>
                </div>
            </header>
            <div className="stylist-container">
                <div className="stylist-badge">
                    <img src={stylistBadge} alt="" />
                </div>
                <div className="row">
                    { sortedStylistData.map(stylist => (
                        <div key={stylist.id} className="col-md-3 stylist-center">
                            <Link to={`/stylist/${stylist.slug}`}>
                                <div className="stylist-item">
                                    <img className="stylists-image" src={stylist.stylist_image} alt="" />
                                    <h6 className="stylist-name">{stylist.first_name} {stylist.last_name}</h6>
                                    <p className="stylist-location">{stylist.salon.join(' | ')}</p>
                                </div>
                            </Link>
                        </div>
                    ))
                    }
                </div>
            </div>
        </Fragment>
    )
};

export default StylistList;