import React, { Fragment } from 'react';
import touristLocations from '../data/tourist.json';
import salonHotels from '../data/salon_hotels.json';
import Instagram from '../images/instagram.png';

const Travel = () => {
    return (
        <Fragment>
            <div className="education-container">
                <h1 className="education-title">Are you traveling to us?</h1>
            </div>

           
            <div className="educator-container">
                <div className="educator-description-container">
                    <h1 className="education-description-title">Travel To Us</h1>
                    <p className="education-description">
                        Client's and Stylist's come from all over the world to visit Habit Salon.
                        Many clients have traveled to come see a Habit Stylist and while they are here
                        they have some fun. Below are a few of our favorite spots in the Valley.
                    </p>
                </div>
            </div>

            <h1 className="education-description-title">Our Favorite Hotels In Scottsdale</h1>
            {
                touristLocations.map(location => (
                    <div key={location.id} style={{ backgroundImage: `url(${location.image})`}} className="location-container">
                        <div className="location-meta-container">
                            <p className="location-title">{location.name}</p>
                            <p className="location-address">{location.address}</p>
                            <a className="travel-instagram" href={location.instagram}>
                              <img src={Instagram} alt=""/>
                            </a>
                           
                        </div>
                    </div>
                ))
            }

            <h1 className="education-description-title">Wanna stay close to the salon?</h1>
            {
                salonHotels.map(location => (
                    <div key={location.id} style={{ backgroundImage: `url(${location.image})`}} className="location-container">
                        <div className="location-meta-container">
                            <p className="location-title">{location.name}</p>
                            <p className="location-address">{location.address}</p>
                            <a className="travel-instagram" href={location.instagram}>
                              <img src={Instagram} alt=""/>
                            </a>
                           
                        </div>
                    </div>
                ))
            }
        </Fragment>
    )
};

export default Travel;