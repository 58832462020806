import React, { Fragment, useEffect } from 'react';
import BrandData from '../../data/brands.json';


const Brands = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return(
        <Fragment>
              <header className="header-area">
                <div className="container">
                    <div className="header-cnt">
                        <p>View some of Habit's Favorite Brands</p>
                    </div>
                </div>
            </header>
            { BrandData.map(b => (
                    <div key={b.name} className="brand-container">
                        <div className="brand-meta">
                            <img src={b.logo} alt="" className="brand-logo" />
                        </div>
                        <p className="brand-description">{b.description}</p>
                        <a href={b.website} className="brand-website">View Website: {b.name}</a>
                    </div>
                ))
            }
        </Fragment>
    )
};

export default Brands;