import React from 'react';
import { Link } from 'react-router-dom';
import Instagram from '../images/instagram.png';

const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="footer-main">
                    <div className="footer-item footer-fst">
                        <ul>
                            <li><Link to='/stylists'>Stylists</Link></li>
                            <li><Link to='/salons'>Salons</Link></li>
                            <li><Link to='/learn'>Learn</Link></li>
                        </ul>
                    </div>
                    <div className="footer-item footer-mdl">
                        <span></span>
                        <a className="instagram" href="https://instagram.com/habitsalon">
                            <img src={Instagram} alt=""/>
                        </a>
                        <a href="https://instagram.com/habitsalon" target="_blank">Follow us on instagram: <br/> @habitsalon</a>
                    </div>
                    <div className="footer-item footer-itemlst">
                        <ul>
                            {/* <li><a href="#">Mailing list</a></li> */}
                            <li><Link to="/brands">Other brands</Link></li>
                            <li><Link to="/careers">Careers</Link></li>
                            <li><Link to="/privacy">Privacy policy</Link></li>
                            <li><Link to="/cancellation">Terms & Cancellation</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="copyright-part">
                    <p>&copy; Habit Salon, All Rights Reserved</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;