import React, { Fragment } from 'react';
import SalonLocations from './SalonLocations';



const Salons = () => {
    return (
        <Fragment>
            <header className="salon-header-area">
                <div className="container">
                    <div className="header-cnt">
                        <p>Choose one of our <br/> 3 locations</p>
                    </div>
                </div>
            </header>
            <h2 className="salon-title salon-margin stylist-center">We have 3 different locations</h2>
           <SalonLocations/>
        </Fragment> 
    )
};

export default Salons;