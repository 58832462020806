import React from 'react';
import { Link } from 'react-router-dom';

const MobileSideNav = ({isOpen, setIsOpen}) => {
    return (
        <div id="mySidenav" className={isOpen ? 'sidenav reveal' : 'sidenav hidden'}>
            <div className="side-upper">
                <Link  onClick={() => setIsOpen(false)} className="logo-title-brown" to='/'>Habit Salon</Link>
                <p  className="closebtn" onClick={() => setIsOpen(false)}>&times;</p>
            </div>
            <ul className="mobile-navigation-list">
                <li onClick={() => setIsOpen(false)}><Link to='/'>HOME</Link></li>
                <li onClick={() => setIsOpen(false)}><Link to='/learn'>LEARN</Link></li>
                <li onClick={() => setIsOpen(false)}><Link to='/stylists'>STYLISTS</Link></li>
                <li onClick={() => setIsOpen(false)}><Link to='/salons'>SALONS</Link></li>
                <li onClick={() => setIsOpen(false)}><Link to='/travel'>TRAVEL</Link></li>
                {/* <li onClick={() => setIsOpen(false)}><Link to='/'>HOW IT WORKS</Link></li> */}
                <li onClick={() => setIsOpen(false)}><Link to='/education'>EDUCATION</Link></li>
                <li onClick={() => setIsOpen(false)}><a href="#"><img src="images/instagram2.png" alt=""/></a></li>
            </ul>
        </div>
    )
}

export default MobileSideNav;