import React, { Fragment } from 'react';
import educatorImage from '../images/educators.jpg';
import hem from '../images/hem.jpg';
import ones from '../images/ones.jpg';

const Education = () => {
    return (
        <Fragment>
            <div className="education-container">
                <h1 className="education-title">We Share What Works For Us</h1>
            </div>

            <div className="educator-container">
                <img src={educatorImage} alt="" className="educator-image" />
                <div className="educator-description-container">
                    <h1 className="education-description-title">Habit Education</h1>
                    <p className="education-description">
                        While being in the hair industry for over 20 years we have learned what works for us and
                        what hasn't. Our belief here at Habit when it comes to any type of knowledge we obtain is 
                        sharing. We don't believe we do it the right way we believe how we do things works and if it works 
                        for us it could work for you. So our education is completely designed around sharing what works rather
                        than lecturing or teaching in a traditional way.
                    </p>
                </div>
            </div>

            <div className="available-classes">
            <h1 className="education-description-title">Habit Education </h1>
                <a target="_blank" rel="noreferrer" href="https://app.habiteducation.com/">
                    <img className="hc-class-images"  src='https://media1-production-mightynetworks.imgix.net/asset/fa5a9bb6-444d-4010-bf59-f844d1678510/Untitled_design__20_.png?ixlib=rails-4.2.0&auto=format&w=256&h=256&fit=crop&impolicy=Avatar' alt="Habit Education" />
                </a>
            <h1 className="education-description-title">Habit Extension Method</h1>
                <a target="_blank" rel="noreferrer" href="https://habitextensionmethod.com">
                    <img className="hc-class-images"  src={hem} alt="Habit Extension Method Class Link" />
                </a>
            <h1 className="education-description-title">One On One</h1>
                <a target="_blank" rel="noreferrer" href="https://habitshadow.as.me/oneonone">
                    <img  className="hc-class-images" src={ones} alt="Habit Extension Method Class Link" />
                </a>
            </div>
        </Fragment>
    )
};

export default Education;