import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import MobileSideNav from './MobileSideNav';

const Nav = () => {
    const [ isOpen, setIsOpen ] = useState(false);



    return (
        <Fragment>
            <div className="header-menu">
                <div className="header-left">
                    <Link className="logo-title" to='/'>Habit Salon</Link>
                </div>
                <div className="header-right">
                    <ul>
                        <li onClick={() => setIsOpen(false)}><Link to='/'>HOME</Link></li>
                        <li onClick={() => setIsOpen(false)}><Link to='/learn'>LEARN</Link></li>
                        <li onClick={() => setIsOpen(false)}><Link to='/stylists'>STYLISTS</Link></li>
                        <li onClick={() => setIsOpen(false)}><Link to='/salons'>SALONS</Link></li>
                        <li onClick={() => setIsOpen(false)}><Link to='/travel'>TRAVEL</Link></li>
                        {/* <li onClick={() => setIsOpen(false)}><Link to='/'>HOW IT WORKS</Link></li> */}
                        <li onClick={() => setIsOpen(false)}><a target="_blank" href='https://habitevents.com'>EVENTS</a></li>

                        <li onClick={() => setIsOpen(false)}><Link to='/education'>EDUCATION</Link></li>
                    </ul>
                </div>
                <div className="hamburger-btn">
                    <span className="hamburger-box" onClick={() => setIsOpen(true)} >
                        <span className="hamburger-inner"></span>
                    </span>
                </div>
            </div>
            <MobileSideNav setIsOpen={setIsOpen} isOpen={ isOpen }/>
        </Fragment>
     
    )

}

export default Nav;