import React from 'react';
import about from '../../images/about.png'


const About = () => {
    return (
        <div id="about" className="about-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <div className="about-left">
                            <img src={about} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="about-right">
                            <h2>About Us</h2>
                            <p>Habit Salon has been trusted by tens of thousands of men and women to enhance their natural beauty through extensions plus our unique color and toning techniques.</p>
                            <p>With locations in Gilbert, AZ and Costa Mesa, CA, people from all over the world come to visit our stylists to get their hair done.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default About;